import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";

import "./PrintClassResults.css";

import { exportToExcel } from '../services/ExcelExporter';
import secondsToTime from "../services/secondsToTime";


function ExportStudentResults(props) {
  // let individualStudentResult = props.individualStudentResult;
  // let assessmentResultsByStudent = props.assessmentResultsByStudent;
  // let student = props.student;
  // let classDetails = props.classDetails;
  // let assessmentName = props.assessment.name;

  // let nameOfClass = classDetails.name;

  // console.log(props)

  // const currentDate = new Date();
  // // const currentDay = currentDate.getDate();
  // // const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
  // // const currentYear = currentDate.getFullYear();


  // function transformDataForExport() {
  //   const studentsData = [];

  //   const allTasks = new Set();
  //   assessmentResultsByStudent.forEach(studentResult => {
  //     studentResult.tasks.forEach(task => {
  //       allTasks.add(task.name);
  //     });
  //   });

  //   const allTasksArray = Array.from(allTasks)

  //   assessmentResultsByStudent.forEach(studentResult => {
  //     const taskScores = studentResult.tasks.map(task => task.score);
  //     const readingScore = taskScores.reduce((a, b) => a + b, 0) / taskScores.length;
  //     const readingScoreFormatted = (typeof readingScore === "number" && !isNaN(readingScore))
  //       ? `${(readingScore * 100).toFixed(2)}%` : "Not Started";

  //     const atRisk = readingScore <= 0.2 ? "Red Flag" : readingScore <= 0.4 ? "Yellow Flag" : "";
  //     const time = (typeof studentResult.time === "number" && !isNaN(studentResult.time))
  //       ? secondsToTime(studentResult.time) : "Not Started";

  //     let studentEntry = {
  //       "Student Name": studentResult.student.name,
  //       "Reading Score": readingScoreFormatted,
  //       "At-Risk": atRisk,
  //     };

  //     allTasksArray.forEach(taskName => {
  //       studentEntry[taskName] = "Partial";
  //     });

  //     studentResult.tasks.forEach(task => {
  //       studentEntry[task.name] = (task.score * 100).toFixed(2) + "%";
  //     });

  //     studentEntry["Time"] = time;
  //     studentEntry["Completed"] = studentResult.completed ? "TRUE" : "FALSE";
  //     studentEntry["Class"] = nameOfClass;

  //     studentsData.push(studentEntry);

  //     studentsData.sort((a, b) => {
  //       let readingScoreA = 0;
  //       let readingScoreB = 0;

  //       if (a["Reading Score"] && typeof a["Reading Score"] === 'string') {
  //         readingScoreA = parseFloat(a["Reading Score"].replace('%', ''));
  //       }
  //       if (b["Reading Score"] && typeof b["Reading Score"] === 'string') {
  //         readingScoreB = parseFloat(b["Reading Score"].replace('%', ''));
  //       }

  //       return readingScoreB - readingScoreA;
  //     });
  //   });

  //   return studentsData;
  // }

  // let filename = `${nameOfClass} - ${assessmentName} - ${currentDate}`;

  // console.log(transformDataForExport())

  const handleExportClick = () => {
    // exportToExcel(transformDataForExport(), filename);
  };

  return (
    <>
      <button className="blue-button" onClick={handleExportClick} style={{ borderRadius: "8px" }}>
        Export to File
      </button>
    </>
  );
}

export default ExportStudentResults;
