import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  getStudents,
  // getClassesAssessmentResults,
  getClassesByAssessmentId,
} from "../services/APIClient";
import HeadingBanner from "../components/HeadingBanner";
import LeftPanel from "../components/LeftPanel";
import SencoStudentsResultsOverview from "../components/SencoStudentResultsOverview";
import OrientationPrompt from "../components/OrientationPrompt";
import { Container, Row, Col } from "react-bootstrap";

function SencoStudentsResults(props) {
  const location = useLocation();

  let name = location.state?.name?.name;

  // Handle undefined state
  let classDetails = location.state?.classDetails?.classDetails || {};
  let allClassesAssessments =
    location.state?.allClassesAssessments?.allClassesAssessments;
  let classAssessments =
    location.state?.classAssessments?.classAssessments || [];
  let teachers = location.state?.teachers?.teachers || [];
  let assessment = location.state?.assessment?.assessment || {};
  let assessmentInstances =
    location.state?.assessment?.assessment.instances || [];
  let schoolid = location.state?.schoolid?.schoolid;
  let teacherid = location.state?.teacherid;
  let classid = classDetails.id;
  let classIds = location.state.classIds.classIds; // Keep this to pass to the new function
  let assessmentId = location.state.assessmentId;
  // let schoolId = location.state.assessmentId;

  const [studentsResponse, setStudentsResponse] = useState([]);
  const [error, setError] = useState(null);
  const [studentResults, setStudentResults] = useState([]);
  const [showAtRiskOnly, setShowAtRiskOnly] = useState(false);
  const [classList, setClassList] = useState([]); // State to store the fetched classes

  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  useEffect(() => {
    if (!assessmentId) {
      setError("Assessment ID is missing.");
      return;
    }

    getClassesByAssessmentId(assessmentId, schoolid)
      .then((response) => {
        setClassList(response); // Update the class list with the API response
      })
      .catch((err) => {
        console.error("Error fetching classes by assessment ID:", err);
        setError("Error fetching classes by assessment ID");
      });
  }, [assessmentId]);

  useEffect(() => {
    // Fetch students for the given class
    if (classid) {
      getStudents(classid)
        .then((response) => {
          setStudentsResponse(response.students || []);
        })
        .catch((err) => {
          console.error("Error fetching students:", err);
          setError("Error fetching students");
        });
    }
  }, [classid]);

  // useEffect(() => {
  //   async function fetchDataInChunks() {
  //     if (!classIds.length || !assessmentId) return;

  //     const chunkSize = 5; // Adjust chunk size based on API/server limits
  //     const chunkedClassIds = [];
  //     for (let i = 0; i < classIds.length; i += chunkSize) {
  //       chunkedClassIds.push(classIds.slice(i, i + chunkSize));
  //     }

  //     const allResults = {}; // Initialize as an object to accumulate results
  //     for (const chunk of chunkedClassIds) {
  //       try {
  //         const chunkResults = await getClassesAssessmentResults(
  //           chunk,
  //           assessmentId
  //         );

  //         // Ensure chunkResults is in the expected object format
  //         if (
  //           chunkResults &&
  //           typeof chunkResults === "object" &&
  //           !Array.isArray(chunkResults)
  //         ) {
  //           // Merge the chunkResults into the allResults object
  //           Object.entries(chunkResults).forEach(([classId, classData]) => {
  //             allResults[classId] = classData; // Add class data to the object by classId
  //           });
  //         } else {
  //           console.warn("Unexpected chunkResults format:", chunkResults);
  //         }
  //       } catch (error) {
  //         console.error("Error fetching chunk:", chunk, error);
  //       }
  //     }

  //     // Set the final accumulated results to state
  //     setStudentResults(allResults); // allResults is now in the original object format
  //   }

  //   fetchDataInChunks();
  // }, [classIds, assessmentId]);

  return (
    <Container fluid className="vh-100">
      <HeadingBanner
        name={name}
        isLoggedIn={true}
        cloud={true}
        teacherid={teacherid}
      />
      <Row className="h-100">
        <LeftPanel />
        <Col sm={{ span: 10, offset: 2 }} className="blue-background">
          <SencoStudentsResultsOverview
            classList={classList} // Pass the dynamic class list to the overview
            // classesResults={studentResults}
            gradeLevel={props.gradeLevel} // Ensure gradeLevel is passed correctly
            teachers={teachers}
            assessment={assessment}
            schoolid={schoolid}
            teacherid={teacherid}
            name={name}
            allClassesAssessments={allClassesAssessments}
            showAtRiskOnly={showAtRiskOnly}
            setShowAtRiskOnly={setShowAtRiskOnly}
            classIds={classIds}
          />
        </Col>
      </Row>
      {isPortrait && <OrientationPrompt />}
      {error && <p className="text-danger">{error}</p>}{" "}
      {/* Display error message if any */}
    </Container>
  );
}

export default SencoStudentsResults;
