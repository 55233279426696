import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  Suspense,
} from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import HeadingBanner from "../components/HeadingBanner";
import {
  whoami,
  getClasses,
  getTeachers,
  getSchools,
  getCountryGrades,
  shareClass,
  getTrialistsBySchool,
} from "../services/APIClient";
import "../components/ClassCard.css";

// Lazy Loaded Components
const ClassCard = React.lazy(() => import("../components/ClassCard"));
const CreateClassCard = React.lazy(() =>
  import("../components/CreateClassCard")
);
const CreateTrialClassCard = React.lazy(() =>
  import("../components/CreateTrialClassCard")
);
const CreateSETClassCard = React.lazy(() =>
  import("../components/CreateSETClassCard")
);
const LeftPanel = React.lazy(() => import("../components/LeftPanel"));
const ClassFilter = React.lazy(() => import("../components/ClassFilter"));
const FreeTrialBanner = React.lazy(() =>
  import("../components/FreeTrialBanner")
);
const OrientationPrompt = React.lazy(() =>
  import("../components/OrientationPrompt")
);

function ClassAdminDashboard() {
  const [schoolid, setSchoolid] = useState(null);
  const [teacherid, setTeacherid] = useState(null);
  const [name, setName] = useState("");
  const [teachers, setTeachers] = useState(null);
  const [rollNumber, setRollNumber] = useState(null);
  const [teacherEmail, setTeacherEmail] = useState(null);
  const [classes, setClasses] = useState([]);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [gradeLevelOptions, setGradeLevelOptions] = useState([]);
  const [filteredClassObjects, setFilteredClassObjects] = useState([]);
  const [mostRecentClassId, setMostRecentClassId] = useState(null);
  const [teacherRoles, setTeacherRoles] = useState(null);
  const [countryId, setCountryId] = useState(null);
  const [trialistDetails, setTrialistDetails] = useState(null);
  const [freeDaysLeft, setFreeDaysLeft] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showTrialEndedModal, setShowTrialEndedModal] = useState(false);
  const { expired = false, purchased = false } = trialistDetails || {};

  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  useEffect(() => {
    const initialize = async () => {
      try {
        const response = await whoami();
        const { schoolid, id, name, additionalRoles, email } =
          response.data.stakeholder;

        setSchoolid(schoolid);
        setTeacherid(id);
        setName(name);
        setTeacherEmail(email);
        setTeacherRoles(additionalRoles);
      } catch (error) {
        console.error("Error during initialization:", error);
      }
    };

    initialize();
  }, []);

  useEffect(() => {
    const fetchSchoolData = async () => {
      try {
        const [teachersResponse, classesResponse, schoolsResponse] =
          await Promise.all([
            getTeachers(schoolid),
            getClasses(),
            getSchools(),
          ]);

        setTeachers(teachersResponse);
        setClasses(classesResponse);

        const uniqueClasses = Array.from(
          new Set(classesResponse.map((cls) => cls.id))
        ).map((id) => {
          return classesResponse.find((cls) => cls.id === id);
        });

        const filtered = uniqueClasses.filter((cls) => {
          const createdYear = new Date(cls.date_created).getFullYear();
          const updatedYear = new Date(cls.date_updated).getFullYear();
          return createdYear === 2023 && updatedYear === 2024;
        });

        setFilteredClassObjects(filtered);

        const sortedClasses = uniqueClasses.sort((a, b) => {
          const aDateCreated = new Date(a.date_created);
          const bDateCreated = new Date(b.date_created);

          if (aDateCreated > bDateCreated) return -1;
          if (aDateCreated < bDateCreated) return 1;

          const aNeedsAssign = a.name.includes("Assign New Classroom teacher");
          const bNeedsAssign = b.name.includes("Assign New Classroom teacher");

          return bNeedsAssign - aNeedsAssign;
        });

        setClasses(sortedClasses);

        if (sortedClasses.length > 0) {
          setMostRecentClassId(sortedClasses[0].id);
        }

        const matchingSchool = schoolsResponse.data.find(
          (school) => school.id === schoolid
        );

        if (matchingSchool) {
          setRollNumber(matchingSchool.rollnumber);
          setCountryId(matchingSchool.countryid);

          const gradesResponse = await getCountryGrades(
            matchingSchool.countryid
          );
          setGradeLevelOptions([gradesResponse]);
        }
      } catch (error) {
        console.error("Error fetching school data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (schoolid) {
      fetchSchoolData();
    }
  }, [schoolid]);

  useEffect(() => {
    const fetchTrialistDetails = async () => {
      try {
        const response = await getTrialistsBySchool(schoolid);
        if (response.length > 0) {
          const details = response[0];
          setTrialistDetails(details);

          const currentDate = new Date();
          const endDate = new Date(details.end_date);
          const timeDiff = endDate - currentDate;
          setFreeDaysLeft(
            Math.max(0, Math.ceil(timeDiff / (1000 * 60 * 60 * 24)))
          );
        }
      } catch (error) {
        console.error("Error fetching trialist details:", error);
      }
    };

    if (schoolid) {
      fetchTrialistDetails();
    }
  }, [schoolid]);

  const sortedFilteredClasses = useMemo(() => {
    return Array.from(new Set(filteredClasses)).sort((a, b) => {
      const aDateCreated = new Date(a.date_created);
      const bDateCreated = new Date(b.date_created);

      if (aDateCreated > bDateCreated) return -1;
      if (aDateCreated < bDateCreated) return 1;

      const aInFiltered = filteredClassObjects.some((obj) => obj.id === a.id);
      const bInFiltered = filteredClassObjects.some((obj) => obj.id === b.id);
      if (aInFiltered && !bInFiltered) return -1;
      if (!aInFiltered && bInFiltered) return 1;

      return 0;
    });
  }, [filteredClasses, filteredClassObjects]);

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  const handleTrialEndedClick = useCallback(
    () => setShowTrialEndedModal(true),
    []
  );
  const handleCloseTrialEndedModal = useCallback(
    () => setShowTrialEndedModal(false),
    []
  );

  return (
    <div>
      <Container fluid className="vh-100">
        <HeadingBanner
          name={`Welcome ${name}!`}
          isLoggedIn={true}
          cloud={true}
          teacherid={teacherid}
          purchased={purchased}
        />
        <Row className="h-100">
          <Suspense fallback={<div>Loading Left Panel...</div>}>
            <LeftPanel />
          </Suspense>
          <Col sm={{ span: 10, offset: 2 }} className="blue-background">
            {loading ? (
              <div className="spinner-container">
                <div className="big-ball-spinner"></div>
              </div>
            ) : (
              <>
                <Row className="row-position">
                  {!purchased ? (
                    <Suspense
                      fallback={<div>Loading Free Trial Banner...</div>}
                    >
                      <FreeTrialBanner
                        schoolid={schoolid}
                        teacherName={name}
                        freeDaysLeft={freeDaysLeft}
                        trialistDetails={trialistDetails}
                      />
                    </Suspense>
                  ) : null}
                  {classes.length > 0 && (
                    <Suspense fallback={<div>Loading Class Filter...</div>}>
                      <ClassFilter
                        classes={classes}
                        setFilteredClasses={setFilteredClasses}
                        gradeLevelOptions={gradeLevelOptions}
                        teacherRoles={teacherRoles}
                      />
                    </Suspense>
                  )}
                  <Row>
                    {expired && !purchased ? (
                      <div
                        className="disabled-card-wrapper"
                        onClick={handleTrialEndedClick}
                      >
                        <Suspense
                          fallback={<div>Loading Create Class Button...</div>}
                        >
                          <CreateClassCard
                            schoolid={schoolid}
                            teacherid={teacherid}
                            name={name}
                            classes={classes}
                            rollNumber={rollNumber}
                            teacherRoles={teacherRoles}
                            countryId={countryId}
                          />
                        </Suspense>
                        <Suspense
                          fallback={
                            <div>Loading Create Trial Class button..</div>
                          }
                        >
                          <CreateTrialClassCard
                            schoolid={schoolid}
                            teacherid={teacherid}
                            name={name}
                            classes={classes}
                            teacherRoles={teacherRoles}
                            countryId={countryId}
                          />
                        </Suspense>
                      </div>
                    ) : (
                      <>
                        <Suspense
                          fallback={<div>Loading Create Class Button...</div>}
                        >
                          <CreateClassCard
                            schoolid={schoolid}
                            teacherid={teacherid}
                            name={name}
                            classes={classes}
                            rollNumber={rollNumber}
                            teacherRoles={teacherRoles}
                            countryId={countryId}
                          />
                        </Suspense>
                        <Suspense
                          fallback={
                            <div>Loading Create Trial Class Button...</div>
                          }
                        >
                          <CreateTrialClassCard
                            schoolid={schoolid}
                            teacherid={teacherid}
                            name={name}
                            classes={classes}
                            teacherRoles={teacherRoles}
                            countryId={countryId}
                          />
                        </Suspense>
                      </>
                    )}
                  </Row>

                  {teacherRoles?.includes("SENCO") && (
                    <Suspense
                      fallback={<div>Loading Create SET Class Button...</div>}
                    >
                      <CreateSETClassCard
                        schoolid={schoolid}
                        teacherid={teacherid}
                        name={name}
                        classes={classes}
                        teacherRoles={teacherRoles}
                        countryId={countryId}
                      />
                    </Suspense>
                  )}
                  {/* <CreateSpecialEdClassCard
                    schoolid={schoolid}
                    teacherid={teacherid}
                    name={name}
                    classes={classes}
                    teacherRoles={teacherRoles}
                    countryId={countryId}
                  /> */}
                </Row>

                {/* New Div with inline styles */}
                {/* {countryId !== "74bb2897-dbbe-41e3-b547-caee866545cf" && (
                  <Row className="my-3">
                    <Col>
                      <div style={whiteBoxStyle}>
                        23/24 {grade1} classrooms were converted to {grade2}{" "}
                        classrooms.
                        <br />
                        <br /> {grade2} teachers, mark yourself as the new{" "}
                        {grade2} teacher to claim your classroom!
                      </div>
                    </Col>
                  </Row>
                )} */}

                <Row className="class-row-position">
                  {sortedFilteredClasses.map((item) => (
                    <Suspense fallback={<div>Loading Class Cards...</div>}>
                      <ClassCard
                        key={item.id}
                        teachers={teachers}
                        classDetails={item}
                        rollNumber={rollNumber}
                        teacherEmail={teacherEmail}
                        schoolid={schoolid}
                        filteredClassObjects={filteredClassObjects}
                        expired={expired}
                        purchased={purchased}
                        teacherRoles={teacherRoles}
                      />
                    </Suspense>
                  ))}
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Container>
      {isPortrait && <OrientationPrompt />}
      <Modal show={showTrialEndedModal} onHide={handleCloseTrialEndedModal}>
        <Modal.Header closeButton>
          <Modal.Title>Trial Ended</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Your trial period has ended. To continue enjoying ALPACA’s full
          features, please proceed with a purchase.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseTrialEndedModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ClassAdminDashboard;
