import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import Accordion from "react-bootstrap/Accordion";
import TaskResultsTable2 from "./TaskResultsTable2.js";
import secondsToTime from "../services/secondsToTime";
import DeleteAnswerModal from "./DeleteAnswerModal.js";
import "./ResultsHistory.css";

function ResultsHistory(props) {
  let studentResults = props.individualStudentResult;
  let isPrintOut = props.isPrintOut;
  let detailedView = props.detailedView;
  let studentName = props.studentName;
  let teacherid = props.teacherid;
  let schoolid = props.schoolid;
  let studentid = props.studentid;

  const [showModal, setShowModal] = useState(false);
  const [modalTask, setModalTask] = useState(null);
  const [modalAssessment, setModalAssessment] = useState(null);
  const [answerId, setAnswerId] = useState(null);
  const [assessmentResults, setAssessmentResults] = useState([]);

  const navigate = useNavigate();

  console.log("teacherid:", teacherid)

  useEffect(() => {
    if (studentResults) {
      setAssessmentResults(studentResults);
    }
  }, [studentResults]);

  // Helper function: Calculate total time
  const calculateTotalTime = (groupedTasks) =>
    groupedTasks.reduce((sum, group) => sum + group.aggregated.duration, 0);

  // Helper function: Calculate reading score
  // const calculateReadingScore = (assessment) => {
  //   const { tasks } = assessment;

  //   if (!tasks || tasks.length === 0) return 0;

  //   // Calculate the total score and count of tasks
  //   const totalScore = tasks.reduce((sum, task) => sum + task.score, 0);
  //   const taskCount = tasks.length;

  //   // Return the average score across all individual tasks
  //   return taskCount > 0 ? Math.round((totalScore / taskCount) * 100) : 0;
  // };

  const calculateReadingScore = (assessment) => {
    if (!assessment || !assessment.tasks || assessment.tasks.length === 0) {
      return 0; // Default to 0 if there are no tasks or if the assessment is not defined
    }

    // Calculate the total score directly from `assessment.tasks`.
    const totalScore = assessment.tasks.reduce(
      (sum, task) => sum + task.score,
      0
    );
    const taskCount = assessment.tasks.length;

    // Return the average score across all tasks.
    return Math.round((totalScore / taskCount) * 100);
  };

  // Derive properties like `totalTime` and `readingScore`
  const derivedResults = useMemo(() => {
    return assessmentResults.map((assessment) => ({
      ...assessment,
      totalTime: calculateTotalTime(assessment.groupedTasks),
      readingScore: calculateReadingScore(assessment), // Use the updated function here
    }));
  }, [assessmentResults]);

  const taskNames = useMemo(() => {
    const allTaskNames = new Set();
    derivedResults.forEach((assessment) => {
      if (assessment.groupedTasks) {
        assessment.groupedTasks.forEach((task) => {
          allTaskNames.add(task.name);
        });
      }
    });
    return Array.from(allTaskNames);
  }, [derivedResults]);

  const getReadingScoreClass = (score) => {
    if (score <= 20) return "low-color";
    if (score > 20 && score <= 40) return "med-color";
    if (score > 40) return "noRisk-color";
    return "";
  };

  const [visibleAccordions, setVisibleAccordions] = useState(new Set());

  const showNestedData = (assessmentIndex) => {
    setVisibleAccordions((prev) => {
      const newVisible = new Set(prev);
      if (newVisible.has(assessmentIndex)) {
        newVisible.delete(assessmentIndex);
      } else {
        newVisible.add(assessmentIndex);
      }
      return newVisible;
    });
  };

  const sortData = (field) => {
    const sortField = field === "atRisk" ? "readingScore" : field;
    setSortCriteria((prev) => {
      const isAscending = prev.field === sortField && prev.order === "asc";
      return { field: sortField, order: isAscending ? "desc" : "asc" };
    });
  };

  const [sortCriteria, setSortCriteria] = useState({
    field: null,
    order: null,
  });

  const sortedAssessmentResults = useMemo(() => {
    let sortedData = [...derivedResults];

    if (sortCriteria.field) {
      sortedData.sort((a, b) => {
        if (taskNames.includes(sortCriteria.field)) {
          const scoreA =
            a.groupedTasks.find((group) => group.name === sortCriteria.field)
              ?.aggregated.score || 0;
          const scoreB =
            b.groupedTasks.find((group) => group.name === sortCriteria.field)
              ?.aggregated.score || 0;
          return sortCriteria.order === "asc"
            ? scoreA - scoreB
            : scoreB - scoreA;
        } else if (sortCriteria.field === "readingScore") {
          return sortCriteria.order === "asc"
            ? a.readingScore - b.readingScore
            : b.readingScore - a.readingScore;
        } else {
          return sortCriteria.order === "asc"
            ? a[sortCriteria.field] - b[sortCriteria.field]
            : b[sortCriteria.field] - a[sortCriteria.field];
        }
      });
    }

    return sortedData;
  }, [derivedResults, sortCriteria, taskNames]);

  const renderTaskScore = (groupedTasks, taskName) => {
    const group = groupedTasks.find((t) => t.name === taskName);
    if (!group) return "";

    const score = Math.round(group.aggregated.score * 100);
    let scoreClass = getReadingScoreClass(score);

    return <span className={`task-score ${scoreClass}`}>{score}%</span>;
  };

  const handleDeleteTask = useCallback(
    (event, assessmentIndex, taskIndex) => {
      event.stopPropagation();
      event.preventDefault();

      const assessment = assessmentResults[assessmentIndex];
      const task = assessment.tasks[taskIndex];
      setModalTask(task?.name);
      setModalAssessment(assessment?.name);
      setAnswerId(task?.answerid);
      setShowModal(true);
    },
    [assessmentResults]
  );

  const handleModalClose = () => setShowModal(false);

  const handleDelete = (taskName) => {
    setAssessmentResults((prevResults) =>
      prevResults.map((assessment) => ({
        ...assessment,
        groupedTasks: assessment.groupedTasks.filter(
          (group) => group.name !== taskName
        ),
      }))
    );
  };

  const handleRTIClick = (task) => {
    navigate("/rti", {
      state: {
        studentResults: props.individualStudentResult,
        isPrintOut: props.isPrintOut,
        detailedView: props.detailedView,
        studentName: props.studentName,
        teacherid: props.teacherid,
        studentid: props.studentid,
        taskDetails: task,
      },
    });
  };

  const getSortIcon = (fieldName) => {
    if (sortCriteria.field === fieldName) {
      return sortCriteria.order === "asc"
        ? process.env.PUBLIC_URL + "/assets/imgs/desc-icon-active.png"
        : process.env.PUBLIC_URL + "/assets/imgs/asc-icon-active.png";
    }
    return process.env.PUBLIC_URL + "/assets/imgs/asc-icon-default.png";
  };

  const allEventKeys = assessmentResults.flatMap(
    (assessment, assessmentIndex) =>
      assessment.groupedTasks.map(
        (_, groupIndex) => `${assessmentIndex}-${groupIndex}`
      )
  );

  return (
    <>
      <Row>
        <Col>
          <div className="">
            <div className="print-only-header">
              <thead className="table-header print-thead">
                <tr>
                  <th
                    colSpan={taskNames.length + 5}
                    className="at-risk-filter-row"
                  >
                    <div className="at-risk-filter">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/imgs/0-20Bar.png"
                        }
                        alt="0-20%"
                        className="risk-bar"
                      />
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/imgs/21-40Bar.png"
                        }
                        alt="21-40%"
                        className="risk-bar"
                      />
                    </div>
                  </th>
                </tr>
                <tr>
                  <th
                    className="header-th-left header-size-results"
                    onClick={() => sortData("name")}
                  >
                    <div className="header-content">
                      Assessment
                      <img
                        className="sort-icon"
                        src={getSortIcon("name")}
                        alt="Sort"
                      />
                    </div>
                  </th>
                  <th
                    className="header-th header-size-results"
                    onClick={() => sortData("readingScore")}
                  >
                    <div className="header-content">
                      Reading Score
                      <img
                        className="sort-icon"
                        src={getSortIcon("readingScore")}
                        alt="Sort"
                      />
                    </div>
                  </th>
                  <th
                    className="header-th header-size-results"
                    onClick={() => sortData("atRisk")}
                  >
                    <div className="header-content">
                      At-Risk
                      <img
                        className="sort-icon"
                        src={getSortIcon("atRisk")}
                        alt="Sort"
                      />
                    </div>
                  </th>
                  {taskNames.map((taskName) => (
                    <th
                      className="header-th header-size-results"
                      key={taskName}
                      onClick={() => sortData(taskName)}
                    >
                      <div className="header-content">
                        {taskName}
                        <img
                          className="sort-icon"
                          src={getSortIcon(taskName)}
                          alt="Sort"
                        />
                      </div>
                    </th>
                  ))}
                  <th
                    className="header-th header-size-results"
                    onClick={() => sortData("totalTime")}
                  >
                    <div className="header-content">
                      Time
                      <img
                        className="sort-icon"
                        src={getSortIcon("totalTime")}
                        alt="Sort"
                      />
                    </div>
                  </th>
                  {!isPrintOut ? (
                    <th className="header-th header-size-results">
                      <div className="header-content">View</div>
                    </th>
                  ) : null}
                </tr>
              </thead>
            </div>
            <div className="table-responsive-wrapper">
              <Table className="custom-table results-history-table">
                <thead className="table-header print-thead">
                  <tr>
                    <th
                      colSpan={taskNames.length + 5}
                      className="at-risk-filter-row"
                    >
                      <div className="at-risk-filter">
                        <img
                          src={
                            process.env.PUBLIC_URL + "/assets/imgs/0-20Bar.png"
                          }
                          alt="0-20%"
                          className="risk-bar"
                        />
                        <img
                          src={
                            process.env.PUBLIC_URL + "/assets/imgs/21-40Bar.png"
                          }
                          alt="21-40%"
                          className="risk-bar"
                        />
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <th
                      className="header-th-left header-size-results"
                      onClick={() => sortData("name")}
                    >
                      <div className="header-content">
                        Screener
                        <img
                          className="sort-icon"
                          src={getSortIcon("name")}
                          alt="Sort"
                        />
                      </div>
                    </th>
                    <th
                      className="header-th header-size-results"
                      onClick={() => sortData("readingScore")}
                    >
                      <div className="header-content">
                        Reading Score
                        <img
                          className="sort-icon"
                          src={getSortIcon("readingScore")}
                          alt="Sort"
                        />
                      </div>
                    </th>
                    <th
                      className="header-th header-size-results"
                      onClick={() => sortData("atRisk")}
                    >
                      <div className="header-content">
                        At-Risk
                        <img
                          className="sort-icon"
                          src={getSortIcon("atRisk")}
                          alt="Sort"
                        />
                      </div>
                    </th>
                    {taskNames.map((taskName) => (
                      <th
                        className="header-th header-size-results"
                        key={taskName}
                        onClick={() => sortData(taskName)}
                      >
                        <div className="header-content">
                          {taskName}
                          <img
                            className="sort-icon"
                            src={getSortIcon(taskName)}
                            alt="Sort"
                          />
                        </div>
                      </th>
                    ))}
                    <th
                      className="header-th header-size-results"
                      onClick={() => sortData("totalTime")}
                    >
                      <div className="header-content">
                        Time
                        <img
                          className="sort-icon"
                          src={getSortIcon("totalTime")}
                          alt="Sort"
                        />
                      </div>
                    </th>
                    {!isPrintOut ? (
                      <th className="header-th header-size-results">
                        <div className="header-content">View</div>
                      </th>
                    ) : null}
                  </tr>
                </thead>
                {sortedAssessmentResults.map((assessment, assessmentIndex) => (
                  <React.Fragment key={assessmentIndex}>
                    <tbody className="result-history-table-rows">
                      <tr
                        id={assessment.name.replace(/\s+/g, "-").toLowerCase()}
                        className="results-history-table-row centre-item"
                      >
                        <td className="align-left">{assessment.name}</td>
                        <td className="centre-item task-badge-parent">
                          {assessment.readingScore ? (
                            <span
                              className={`reading-score ${getReadingScoreClass(
                                assessment.readingScore
                              )}`}
                            >
                              {assessment.readingScore}%
                            </span>
                          ) : null}
                        </td>
                        <td>
                          {assessment.readingScore !== undefined &&
                          assessment.readingScore < 20 ? (
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/imgs/risk-flag-red.png"
                              }
                              className="flag-size-results"
                              alt="Red Flag"
                            />
                          ) : assessment.readingScore >= 20 &&
                            assessment.readingScore <= 40 ? (
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/imgs/risk-flag-yellow.png"
                              }
                              className="flag-size-results"
                              alt="Yellow Flag"
                            />
                          ) : null}
                        </td>
                        {taskNames.map((taskName) => (
                          <td
                            className="results-task-score centre-item task-badge-parent"
                            data-value={taskName
                              .replace(/\s+/g, "-")
                              .toLowerCase()}
                            key={taskName}
                          >
                            {renderTaskScore(assessment.groupedTasks, taskName)}
                          </td>
                        ))}
                        <td
                          className="results-task-score centre-item"
                          data-value={assessment.name}
                        >
                          {secondsToTime(assessment.totalTime)}
                        </td>
                        {!isPrintOut ? (
                          <td data-toggle="toggle">
                            <img
                              className="collapse-click"
                              onClick={() => showNestedData(assessmentIndex)}
                              src={
                                visibleAccordions.has(assessmentIndex)
                                  ? "../assets/imgs/minus-icon-small.png"
                                  : "../assets/imgs/plus-icon-small.png"
                              }
                            />
                          </td>
                        ) : null}
                      </tr>
                    </tbody>
                    {detailedView && (
                      <td
                        colSpan="12"
                        id={
                          assessment.name.replace(/\s+/g, "-").toLowerCase() +
                          "-data"
                        }
                        className={`result-accordian-width ${
                          visibleAccordions.has(assessmentIndex) || isPrintOut
                            ? ""
                            : "hide"
                        }`}
                      >
                        <Accordion
                          className="results-accordion"
                          defaultActiveKey={isPrintOut ? allEventKeys : []}
                        >
                          {assessment.tasks.map((task, taskIndex) => (
                            <Accordion.Item
                              eventKey={`${assessmentIndex}-${taskIndex}`}
                              key={`${assessmentIndex}-${taskIndex}`}
                            >
                              <Accordion.Header className="selected-assessment-accordion-head">
                                <Row className="task-results-table-row">
                                  <Col className="col-1">
                                    {/* Delete Button */}
                                    <button
                                      className="delete-button"
                                      onClick={(e) =>
                                        handleDeleteTask(
                                          e,
                                          assessmentIndex,
                                          taskIndex
                                        )
                                      }
                                    >
                                      &#10005;
                                    </button>
                                  </Col>
                                  <Col className="col-9 align-left">
                                    {task.name}
                                  </Col>
                                  {/* Conditionally render the RTI button */}
                                  {/* {task.score < 0.4 ? (
                                    <Col className="col-auto">
                                      <button
                                        className="rti-button"
                                        onClick={() => handleRTIClick(task)}
                                      >
                                        RTI
                                      </button>
                                    </Col>
                                  ) : (
                                    <Col className="col-auto"></Col>
                                  )} */}
                                  <Col>{secondsToTime(task.duration)}</Col>
                                </Row>
                              </Accordion.Header>
                              <Accordion.Body>
                                <TaskResultsTable2 taskAnswers={task.answers} />
                              </Accordion.Body>
                            </Accordion.Item>
                          ))}
                        </Accordion>
                      </td>
                    )}
                  </React.Fragment>
                ))}
              </Table>
            </div>
          </div>
        </Col>
      </Row>
      <DeleteAnswerModal
        show={showModal}
        handleClose={handleModalClose}
        taskName={modalTask}
        studentName={studentName}
        assessmentName={modalAssessment}
        handleDelete={handleDelete}
        answerId={answerId}
        teacherid={teacherid}
      />
    </>
  );
}

export default ResultsHistory;
