import React, { useState, useEffect } from "react";
import HeadingBanner from "../components/HeadingBanner";
import Login from "../components/Login";
import RightPanel from "../components/RightPanel";
import { Container, Row, Col } from "react-bootstrap";
import SignUpForm from "../components/SignUpForm";
import OrientationPrompt from "../components/OrientationPrompt";

import "../components/RightPanel.css";
import "../components/Login.css";

function App(props) {
  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  return (
    <Container fluid className="vh-100 d-flex flex-column">
      <HeadingBanner
        isLoggedIn={false}
        cloud={true}
        name={"Assessing Letter & Phonemic Awareness Class Assistant"}
      />
      <Row className="h-100">
        <Col sm={8}>
          <Login />
          {/* <div style={{ textAlign: 'center', marginTop: '20%', fontFamily: 'Arial, sans-serif', border: "1px solid black"}}>
            <h2 style={{ color: '#2c3e50' }}>The ALPACA Teacher Dashboard is Temporarily Unavailable</h2>
            <h3 style={{ color: '#7f8c8d' }}>We are performing upgrades.</h3>
            <h3 style={{ color: '#16a085' }}> */}
              {/* Service will resume at <strong>10:00 AM on January th, 2025</strong>. */}
            {/* </h3>
            <h3 style={{ color: '#7f8c8d' }}>Thank you for your understanding and patience.</h3>
          </div> */}
        </Col>
        <RightPanel
          msg="Don't have an account yet? Check us out on alpaca-assessment.com"
          label="Alpaca Assessment"
          path="https://www.alpaca-assessment.com/"
        />
      </Row>
      {isPortrait && <OrientationPrompt />}{" "}
      {/* Render OrientationPrompt based on isPortrait */}
    </Container>
  );
}

export default App;
