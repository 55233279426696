import React, { useEffect, useState, useMemo, useCallback, Suspense } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import HeadingBanner from "../components/HeadingBanner";
import {
  whoami,
  getClasses,
  getTeachers,
  getSchools,
  getCountryGrades,
  getTrialistsBySchool,
} from "../services/APIClient";
import "../components/ClassCard.css";

// Lazy Loaded Components
const ResultsDashCard = React.lazy(() => import("../components/ResultsDashCard"));
const LeftPanel = React.lazy(() => import("../components/LeftPanel"));
const AlpacaInfoGesture = React.lazy(() => import("../components/AlpacaInfoGesture"));
const ClassFilter = React.lazy(() => import("../components/ClassFilter"));
const FreeTrialBanner = React.lazy(() => import("../components/FreeTrialBanner"));
const OrientationPrompt = React.lazy(() => import("../components/OrientationPrompt"));

function ResultsDashboard() {
  const navigate = useNavigate();
  const [schoolid, setSchoolid] = useState(null);
  const [teacherid, setTeacherid] = useState(null);
  const [name, setName] = useState(null);
  const [teachers, setTeachers] = useState([]);
  const [firstname, setFirstname] = useState(null);
  const [speechText, setSpeechText] = useState(null);
  const [classes, setClasses] = useState([]);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [rollNumber, setRollNumber] = useState(null);
  const [gradeLevelOptions, setGradeLevelOptions] = useState([]);
  const [teacherRoles, setTeacherRoles] = useState(null);
  const [trialistDetails, setTrialistDetails] = useState(null);
  const [freeDaysLeft, setFreeDaysLeft] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  const { expired = false, purchased = false } = trialistDetails || {};

  // Memoized derived state
  const sortedFilteredClasses = useMemo(() => {
    return [...filteredClasses].sort(
      (a, b) => new Date(b.date_created) - new Date(a.date_created)
    );
  }, [filteredClasses]);

  const handleOrientationChange = useCallback(() => {
    setIsPortrait(window.innerWidth < window.innerHeight);
  }, []);

  const handleSENCOView = useCallback(() => {
    navigate("/sencoresults/detail", {
      state: { teachers, name, gradeLevelOptions, teacherid, schoolid },
    });
  }, [navigate, teachers, name, gradeLevelOptions, teacherid, schoolid]);

  useEffect(() => {
    window.addEventListener("resize", handleOrientationChange);
    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, [handleOrientationChange]);

  useEffect(() => {
    const initialize = async () => {
      try {
        const response = await whoami();
        const { schoolid, id, name, additionalRoles } = response.data.stakeholder;
        setSchoolid(schoolid);
        setTeacherid(id);
        setName(name);
        setTeacherRoles(additionalRoles);
        setFirstname(name.split(" ")[0]);
      } catch (error) {
        console.error("Error fetching user information:", error);
      }
    };

    initialize();
  }, []);

  useEffect(() => {
    const fetchSchoolData = async () => {
      try {
        const [teachersResponse, classesResponse, schoolsResponse] = await Promise.all([
          getTeachers(schoolid),
          getClasses(),
          getSchools(),
        ]);

        setTeachers(teachersResponse);

        const uniqueClasses = Array.from(new Set(classesResponse.map((cls) => cls.id))).map(
          (id) => classesResponse.find((cls) => cls.id === id)
        );
        setClasses(uniqueClasses);

        const matchingSchool = schoolsResponse.data.find((school) => school.id === schoolid);
        if (matchingSchool) {
          const { rollnumber, countryid } = matchingSchool;
          setRollNumber(rollnumber);
          const gradesResponse = await getCountryGrades(countryid);
          setGradeLevelOptions([gradesResponse]);
        }
      } catch (error) {
        console.error("Error fetching school data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (schoolid) fetchSchoolData();
  }, [schoolid]);

  useEffect(() => {
    const fetchTrialistDetails = async () => {
      try {
        const response = await getTrialistsBySchool(schoolid);
        if (response.length > 0) {
          const details = response[0];
          setTrialistDetails(details);

          const currentDate = new Date();
          const endDate = new Date(details.end_date);
          setFreeDaysLeft(Math.max(0, Math.ceil((endDate - currentDate) / (1000 * 60 * 60 * 24))));
        }
      } catch (error) {
        console.error("Error fetching trialist details:", error);
      }
    };

    if (schoolid) fetchTrialistDetails();
  }, [schoolid]);

  useEffect(() => {
    setSpeechText(
      `Whoa, slow down there ${firstname}. We need to create a class first. Click into "Admin" on the Left to get this started.`
    );
  }, [firstname]);

  return (
    <div>
      <Container fluid className="vh-100">
        <HeadingBanner
          name={`${name}'s Results`}
          isLoggedIn={true}
          cloud={true}
          teacherid={teacherid}
          purchased={purchased}
        />
        <Row className="h-100">
          <Suspense fallback={<div>Loading Left Panel...</div>}>
            <LeftPanel />
          </Suspense>
          <Col sm={{ span: 10, offset: 2 }} className="blue-background">
            {loading ? (
              <div className="spinner-container">
                <div className="big-ball-spinner"></div>
              </div>
            ) : (
              <Row className="row-position">
                {!purchased && (
                  <Suspense fallback={<div>Loading Free Trial Banner...</div>}>
                    <FreeTrialBanner
                      schoolid={schoolid}
                      teacherName={name}
                      freeDaysLeft={freeDaysLeft}
                      trialistDetails={trialistDetails}
                    />
                  </Suspense>
                )}
                {classes.length > 0 && (
                  <Suspense fallback={<div>Loading Class Filter...</div>}>
                    <ClassFilter
                      classes={classes}
                      setFilteredClasses={setFilteredClasses}
                      gradeLevelOptions={gradeLevelOptions}
                      teacherRoles={teacherRoles}
                    />
                  </Suspense>
                )}
                {teacherRoles?.includes("SENCO") && (
                  <Row>
                    <Col className="text-start">
                      <button className="blue-button" onClick={handleSENCOView}>
                        SENCO Results View
                      </button>
                    </Col>
                  </Row>
                )}
                {classes.length === 0 ? (
                  <Col className="alpaca-message-positioner">
                    <Suspense fallback={<div>Loading Alpaca Info Gesture...</div>}>
                      <AlpacaInfoGesture speechText={speechText} />
                    </Suspense>
                  </Col>
                ) : (
                  sortedFilteredClasses.map((item, index) => (
                    <Suspense key={item.id} fallback={<div>Loading Results Dash Card...</div>}>
                      <ResultsDashCard
                        key={`${item.id}-${index}`}
                        teachers={teachers}
                        classDetails={item}
                        rollNumber={rollNumber}
                        teacherid={teacherid}
                        schoolid={schoolid}
                      />
                    </Suspense>
                  ))
                )}
              </Row>
            )}
          </Col>
        </Row>
      </Container>
      {isPortrait && (
        <Suspense fallback={<div>Loading Orientation Prompt...</div>}>
          <OrientationPrompt />
        </Suspense>
      )}
    </div>
  );
}

export default ResultsDashboard;


// import React, { useEffect, useState } from "react";
// import { Container, Row, Col, Button } from "react-bootstrap"; // Import Button from react-bootstrap
// import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
// import HeadingBanner from "../components/HeadingBanner";
// import ResultsDashCard from "../components/ResultsDashCard";
// import LeftPanel from "../components/LeftPanel";
// import AlpacaInfoGesture from "../components/AlpacaInfoGesture";
// import {
//   whoami,
//   getClasses,
//   getTeachers,
//   getSchools,
//   getCountryGrades,
//   getTrialistsBySchool
// } from "../services/APIClient";
// import ClassFilter from "../components/ClassFilter";
// import OrientationPrompt from "../components/OrientationPrompt";
// import FreeTrialBanner from "../components/FreeTrialBanner";


// function ResultsDashboard() {
//   const navigate = useNavigate(); // Initialize navigate
//   const [schoolid, setSchoolid] = useState(null);
//   const [teacherid, setTeacherid] = useState(null);
//   const [name, setName] = useState(null);
//   const [teachers, setTeachers] = useState(null);
//   const [firstname, setFirstname] = useState(null);
//   const [speechText, setSpeechText] = useState(null);
//   const [classes, setClasses] = useState([]);
//   const [rollNumber, setRollNumber] = useState(null);
//   const [filteredClasses, setFilteredClasses] = useState([]);
//   const [gradeLevelOptions, setGradeLevelOptions] = useState([]);
//   const [teacherRoles, setTeacherRoles] = useState(null);
//   const [isPortrait, setIsPortrait] = useState(
//     window.matchMedia("(orientation: portrait)").matches
//   );

//   const [trialistDetails, setTrialistDetails] = useState(null);
//   const [freeDaysLeft, setFreeDaysLeft] = useState(null);
//   const [showTrialEndedModal, setShowTrialEndedModal] = useState(false);
//   const {
//     expired = false,
//     purchased = false,
//   } = trialistDetails || {};

//   console.log(schoolid, "SIDDD");

//   useEffect(() => {
//     const handleOrientationChange = () => {
//       setIsPortrait(window.innerWidth < window.innerHeight);
//     };

//     window.addEventListener("resize", handleOrientationChange);

//     return () => {
//       window.removeEventListener("resize", handleOrientationChange);
//     };
//   }, []);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     whoami().then((response) => {
//       setSchoolid(response.data.stakeholder.schoolid);
//       setTeacherid(response.data.stakeholder.id);
//       setName(response.data.stakeholder.name);
//       setTeacherRoles(response.data.stakeholder.additionalRoles);
//     });
//   }, []);

//   useEffect(() => {
//     async function fetchSchoolData() {
//       try {
//         const teachersResponse = await getTeachers(schoolid);
//         setTeachers(teachersResponse);

//         let classesResponse = await getClasses();

//         classesResponse = classesResponse.filter(
//           (classItem, index, self) =>
//             index === self.findIndex((t) => t.id === classItem.id)
//         );

//         classesResponse.sort(
//           (a, b) => new Date(b.date_created) - new Date(a.date_created)
//         );
//         setClasses(classesResponse);

//         const schoolsResponse = await getSchools();
//         const matchingSchool = schoolsResponse.data.find(
//           (school) => school.id === schoolid
//         );

//         if (matchingSchool) {
//           const rollNumber = matchingSchool.rollnumber;
//           const countryid = matchingSchool.countryid;
//           setRollNumber(rollNumber);

//           getCountryGrades(countryid)
//             .then((gradesResponse) => {
//               setGradeLevelOptions([gradesResponse]);
//             })
//             .catch((error) => {
//               console.error("Error fetching country grades:", error);
//             });
//         }
//       } catch (error) {
//         console.error("Error fetching school data:", error);
//       }
//     }

//     if (schoolid) {
//       fetchSchoolData().finally(() => {
//         setLoading(false);
//       });
//     }
//   }, [schoolid]);

//   useEffect(() => {
//     if (name) {
//       let nameArray = name.split(" ");
//       setFirstname(nameArray[0]);
//     }
//   }, [name, schoolid]);

//   useEffect(() => {
//     setSpeechText(
//       `Whoa, slow down there ${firstname}. We need to create a class first. Click into "Admin" on the Left to get this started`
//     );
//   }, [firstname]);

//   useEffect(() => {
//     window.HubSpotConversations?.widget?.refresh();
//   }, []);

//   useEffect(() => {
//     const fetchTrialistDetails = async () => {
//       try {
//         const response = await getTrialistsBySchool(schoolid); // Fetch data
//         if (response.length > 0) {
//           const details = response[0]; // Assuming we use the first trial entry
//           setTrialistDetails(details);

//           // Calculate freeDaysLeft
//           const currentDate = new Date();
//           const endDate = new Date(details.end_date);
//           const timeDiff = endDate - currentDate; // Difference in milliseconds
//           const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); // Convert to days
//           setFreeDaysLeft(daysLeft > 0 ? daysLeft : 0); // Ensure non-negative value
//         }
//       } catch (error) {
//         console.error("Failed to fetch trialist details:", error);
//       }
//     };
//     fetchTrialistDetails();
//   }, [schoolid]);


//   // Function to handle button click
//   const handleSENCOView = () => {
//     // Navigate to the same route as the ResultsDashCard link
//     navigate("/sencoresults/detail", {
//       state: {
//         // classDetails: { classes: filteredClasses }, // Modify as needed
//         teachers,
//         name,
//         gradeLevelOptions,
//         teacherid,
//         schoolid,
//       },
//     });
//   };

//   console.log(teacherRoles);

//   return (
//     <div>
//       <Container fluid className="vh-100">
//         <HeadingBanner
//           name={`${name}'s Results`}
//           isLoggedIn={true}
//           cloud={true}
//           teacherid={teacherid}
//           purchased={purchased}
//         />

//         <Row className="h-100">
//           <LeftPanel />
//           <Col sm={{ span: 10, offset: 2 }} className="blue-background">
//             {loading ? (
//               <div className="spinner-container">
//                 <div className="big-ball-spinner"></div>
//               </div>
//             ) : (
//               <Row className="row-position">
//                 {!purchased ? (
//                   <FreeTrialBanner
//                     schoolid={schoolid}
//                     teacherName={name}
//                     freeDaysLeft={freeDaysLeft}
//                     trialistDetails={trialistDetails}
//                   />
//                 ) : (
//                   null
//                 )}
//                 {classes.length > 0 && (
//                   <>
//                     <ClassFilter
//                       classes={classes}
//                       setFilteredClasses={setFilteredClasses}
//                       gradeLevelOptions={gradeLevelOptions}
//                       teacherRoles={teacherRoles}
//                     />
//                     <br />
//                     {/* Conditionally show the SENCO button here */}
//                     {teacherRoles?.includes("SENCO") && (
//                       <Row>
//                         <Col className="text-start">
//                           <button
//                             className="blue-button"
//                             onClick={handleSENCOView}
//                           >
//                             SENCO Results View
//                           </button>
//                         </Col>
//                       </Row>
//                     )}
//                   </>
//                 )}
//                 {classes.length === 0 ? (
//                   <>
//                     <Col className="col-2"></Col>
//                     <Col className="alpaca-message-positioner">
//                       <AlpacaInfoGesture speechText={speechText} />
//                     </Col>
//                   </>
//                 ) : (
//                   filteredClasses.map((item, index) => (
//                     <ResultsDashCard
//                       key={`${item.id}-${index}`}
//                       teachers={teachers}
//                       classDetails={item}
//                       rollNumber={rollNumber}
//                       teacherid={teacherid}
//                       schoolid={schoolid}
//                     />
//                   ))
//                 )}
//               </Row>
//             )}
//           </Col>
//         </Row>
//       </Container>
//       {isPortrait && <OrientationPrompt />}
//     </div>
//   );
// }

// export default ResultsDashboard;
