import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./ReturnToResults.css";

function ReturnToClassResults(props) {
  const navigate = useNavigate(); // Initialize the navigate hook

  const {
    classDetails,
    classAssessments,
    classAssessmentsArray,
    teachers,
    assessment,
    schoolid,
    teacherid,
    fromSencoResults,
  } = props;

  console.log(fromSencoResults);

  // Conditional rendering based on fromSencoResults
  if (fromSencoResults) {
    return (
      <button className="blue-button" onClick={() => navigate(-1)}>
        Back
      </button>
    );
  }

  // Default behavior using Link if fromSencoResults is false
  return (
    <Link
      to={`/studentsresults`}
      state={{
        assessment,
        classDetails,
        classAssessments,
        classAssessmentsArray,
        teachers,
        gradeLevel: classDetails.grade_level,
        schoolid,
        teacherid,
      }}
    >
      <button className="blue-button">Back</button>
    </Link>
  );
}

export default ReturnToClassResults;



// import React from "react";
// import { Link, useNavigate } from "react-router-dom";
// import "./ReturnToResults.css";

// function ReturnToClassResults(props) {
//   const navigate = useNavigate(); // Initialize the navigate hook

//   let classDetails = props.classDetails;
//   let classAssessments = props.classAssessments;
//   let classAssessmentsArray = props.classAssessmentsArray;
//   let teachers = props.teachers;
//   let assessment = props.assessment;
//   let name = classDetails.name;
//   let gradeLevel = classDetails.grade_level;
//   let gradeLevel1 = classDetails.grade_level1;
//   let gradeLevel2 = classDetails.grade_level2;
//   let trialClass = classDetails.trial_class;
//   let schoolid = props.schoolid;
//   let teacherid = props.teacherid;
//   let fromSencoResults = props.fromSencoResults;

//   console.log(fromSencoResults);

//   // Conditional rendering based on fromSencoResults
//   if (fromSencoResults) {
//     return (
//       <button className="blue-button" onClick={() => navigate(-1)}>
//         Back
//       </button>
//     );
//   }

//   // Default behavior using Link if fromSencoResults is false
//   return (
//     <Link
//       to={`/studentsresults`}
//       state={{
//         assessment: { assessment },
//         classDetails: { classDetails },
//         classAssessments: { classAssessments },
//         classAssessmentsArray: { classAssessmentsArray },
//         teachers: { teachers },
//         gradeLevel: { gradeLevel },
//         schoolid: { schoolid },
//         teacherid: { teacherid },
//       }}
//     >
//       <button className="blue-button">Back</button>
//     </Link>
//   );
// }

// export default ReturnToClassResults;
