import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import "./AssessmentStatus.css";

function ResultsStatus({
  classDetails,
  classAssessmentsValues = [],
  teachers = [],
  schoolid,
  teacherid,
  selectAssessmentId,
}) {
  const navigate = useNavigate();

  const assessmentOrder = useMemo(
    () => ({
      baseline: 1,
      midpoint: 2,
      "end of year": 3,
    }),
    []
  );

  const getIconFileName = useCallback((assessmentName) => {
    let name = assessmentName?.toLowerCase() || "";

    if (name.includes("baseline") || name.includes("autumn")) {
      return "autumn";
    } else if (name.includes("midpoint") || name.includes("spring")) {
      return "spring";
    } else if (name.includes("end of year") || name.includes("summer")) {
      return "summer";
    }

    // Remove "Instance" and handle task-based naming
    name = name.replace(" instance", "").trim();
    const words = name.split(" ");
    return words.slice(0, 2).join("") || words[0];
  }, []);

  const classAssessmentsArray = useMemo(() => {
    const sortAssessments = (a, b) => {
      const getSortKey = (name) => {
        name = name.toLowerCase();
        if (name.includes("baseline") || name.includes("autumn"))
          return assessmentOrder["baseline"];
        if (name.includes("midpoint") || name.includes("spring"))
          return assessmentOrder["midpoint"];
        if (name.includes("end of year") || name.includes("summer"))
          return assessmentOrder["end of year"];
        return 4; // Default sort key
      };

      return getSortKey(a.name) - getSortKey(b.name);
    };

    return classAssessmentsValues
      .map((assessment) => ({
        ...assessment,
        imageName: getIconFileName(assessment.name),
      }))
      .sort(sortAssessments);
  }, [classAssessmentsValues, getIconFileName, assessmentOrder]);

  const toAssessmentResults = useCallback(
    (assessment) => {
      navigate("/studentsresults", {
        state: {
          assessment,
          classDetails,
          classAssessments: classAssessmentsValues,
          classAssessmentsArray,
          teachers,
          gradeLevel: classDetails.grade_level,
          schoolid,
          teacherid,
        },
      });
    },
    [
      navigate,
      classDetails,
      classAssessmentsValues,
      classAssessmentsArray,
      teachers,
      schoolid,
      teacherid,
    ]
  );

  return (
    <Row>
      {classAssessmentsArray.length === 0 ? (
        <Col>
          <Alert variant="info" className="text-center">
            <FontAwesomeIcon
              icon={faExclamationCircle}
              size="3x"
              className="mb-3"
            />
            <h4>No Results Available</h4>
            <p>
              Once your students begin screening, the results will start to
              populate here.
            </p>
          </Alert>
        </Col>
      ) : (
        classAssessmentsArray.map((classAssessment, index) => (
          <Col className="col-4" key={classAssessment.id || index}>
            <button
              className={`light-blue-button assessment-button ${
                selectAssessmentId === classAssessment.id ? "selected" : ""
              }`}
              onClick={() => toAssessmentResults(classAssessment)}
            >
              {classAssessment.name.replace(" Instance", "")} <br />
              <img
                src={`/assets/imgs/${classAssessment.imageName}-icon.png`}
                className="season-assessment-icon"
                alt={classAssessment.name}
              />
            </button>
          </Col>
        ))
      )}
    </Row>
  );
}

ResultsStatus.propTypes = {
  classDetails: PropTypes.shape({
    grade_level: PropTypes.string,
    grade_level1: PropTypes.string,
    grade_level2: PropTypes.string,
    trial_class: PropTypes.bool,
  }).isRequired,
  classAssessmentsValues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  teachers: PropTypes.array,
  schoolid: PropTypes.string,
  teacherid: PropTypes.string,
  selectAssessmentId: PropTypes.string,
};

export default ResultsStatus;


// import React from "react";
// import { useNavigate } from "react-router-dom";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import Alert from "react-bootstrap/Alert";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
// import "./AssessmentStatus.css";

// function ResultsStatus(props) {
//   let classDetails = props.classDetails;
//   let classAssessments = props.classAssessmentsValues;
//   let teachers = props.teachers;

//   let gradeLevel = classDetails.grade_level;
//   let gradeLevel1 = classDetails.grade_level1;
//   let gradeLevel2 = classDetails.grade_level2;
//   let trialClass = classDetails.trial_class;

//   let schoolid = props.schoolid;
//   let teacherid = props.teacherid;

//   const assessmentOrder = {
//     baseline: 1,
//     midpoint: 2,
//     "end of year": 3,
//   };

//   const sortAssessments = (a, b) => {
//     const getSortKey = (name) => {
//       name = name.toLowerCase();
//       if (name.includes("baseline") || name.includes("autumn"))
//         return assessmentOrder["baseline"];
//       if (name.includes("midpoint") || name.includes("spring"))
//         return assessmentOrder["midpoint"];
//       if (name.includes("end of year") || name.includes("summer"))
//         return assessmentOrder["end of year"];
//       return 4; // Default sort key
//     };

//     let aOrder = getSortKey(a.name);
//     let bOrder = getSortKey(b.name);

//     return aOrder - bOrder;
//   };

//   const getIconFileName = (assessmentName) => {
//     let name = assessmentName.toLowerCase();

//     // Season-based logic
//     if (
//       name.includes("baseline") ||
//       name.includes("point 1") ||
//       name.includes("autumn")
//     ) {
//       return "autumn";
//     } else if (
//       name.includes("midpoint") ||
//       name.includes("point 2") ||
//       name.includes("spring")
//     ) {
//       return "spring";
//     } else if (
//       name.includes("end of year") ||
//       name.includes("point 3") ||
//       name.includes("summer")
//     ) {
//       return "summer";
//     }

//     // Remove "Instance" if it exists in the name
//     name = name.replace(" instance", "");

//     // Task-based logic for other assessments
//     const words = name.split(" ");
//     let imageName = words.slice(0, 2).join(""); // Combine the first two words

//     // Ensure the generated name is appropriate
//     if (words.length === 1) {
//       imageName = words[0]; // If only one word, use it as the image name
//     }

//     return imageName;
//   };

//   let classAssessmentsArray = classAssessments.map((classAssessment) => {
//     return {
//       ...classAssessment,
//       imageName: getIconFileName(classAssessment.name),
//     };
//   });

//   classAssessmentsArray.sort(sortAssessments);

//   const navigate = useNavigate();

//   const toAssessmentResults = (assessment) => {
//     navigate("/studentsresults", {
//       state: {
//         assessment: { assessment },
//         classDetails: { classDetails },
//         classAssessments: { classAssessments },
//         classAssessmentsArray: { classAssessmentsArray },
//         teachers: { teachers },
//         gradeLevel: { gradeLevel },
//         schoolid: { schoolid },
//         teacherid: { teacherid },
//       },
//     });
//   };

//   return (
//     <Row>
//       {classAssessmentsArray.length === 0 ? (
//         <Col>
//           <Alert variant="info" className="text-center">
//             <FontAwesomeIcon
//               icon={faExclamationCircle}
//               size="3x"
//               className="mb-3"
//             />
//             <h4>No Results Available</h4>
//             <p>
//               Once your students begin screening, the results will start to
//               populate here.
//             </p>
//           </Alert>
//         </Col>
//       ) : (
//         classAssessmentsArray.map((classAssessment, index) => (
//           <Col className="col-4" key={index}>
//             <button
//               className={`light-blue-button assessment-button ${
//                 props.selectAssessmentId === classAssessment.id
//                   ? "selected"
//                   : ""
//               }`}
//               onClick={() => {
//                 toAssessmentResults(classAssessment);
//               }}
//             >
//               {classAssessment.name.replace(" Instance", "")} <br />
//               <img
//                 src={`/assets/imgs/${classAssessment.imageName}-icon.png`}
//                 className="season-assessment-icon"
//                 alt={classAssessment.name}
//               />
//             </button>
//           </Col>
//         ))
//       )}
//     </Row>
//   );
// }

// export default ResultsStatus;
