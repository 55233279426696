import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./ClassOverview.css";

import SencoResultsStatus from "./SencoResultsStatus"; // Import the new SencoResultsStatus component
import ReportsButton from "./ReportsButton";
import SimpleNavigatioButton from "./SimpleNavigationButton";
import GradeCard from "./GradeCard";

function SencoResultsClassOverview(props) {
  let gradeLevel = props.gradeLevel;
  let teachers = props.teachers;
  let teacherid = props.teacherid;
  let schoolid = props.schoolid;
  let classIds = props.classIds;
  let name = props.name;

  console.log(props)

  const [allClassesAssessments, setAllClassesAssessments] = useState([]);
  const { gradeLevel1Assessments, gradeLevel2Assessments } = props;

  // Assuming you fetch the aggregated class assessments from the props or from an API call
  useEffect(() => {
    if (props.allClassesAssessments) {
      setAllClassesAssessments(props.allClassesAssessments);
    }
  }, [props.allClassesAssessments]);

  return (
    <div className="class-overview-container">
      <Container className="container-shape">
        <Row className="page-description-bar">
          <Col className="col-7">
            <Row className="inline-block">
              <Col className="col-6">
                <h2 className="page-description-header">
                  SENCO Results Overview
                </h2>
              </Col>
              <Col className="col-3">
                {/* <GradeCard gradeLevel={gradeLevel} /> */}
              </Col>
            </Row>
            <p className="paragraph-font-size">
              Select the assessment that you would like to see the results for.
            </p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            {/* Displaying SencoResultsStatus for grade level 1 */}
            {gradeLevel1Assessments.length > 0 ? (
              <SencoResultsStatus
                allClassesAssessments={gradeLevel1Assessments} // Pass grade level 1 assessments
                teachers={teachers}
                schoolid={schoolid}
                classIds={classIds}
                name={name}
                teacherid={teacherid}
              />
            ) : (
              <p>No grade level 1 assessments available.</p> // Message when no grade level 1 assessments are available
            )}

            {/* Displaying SencoResultsStatus for grade level 2 */}
            {gradeLevel2Assessments.length > 0 ? (
              <SencoResultsStatus
                allClassesAssessments={gradeLevel2Assessments} // Pass grade level 2 assessments
                teachers={teachers}
                schoolid={schoolid}
                classIds={classIds}
                name={name}
                teacherid={teacherid}
              />
            ) : (
              <p>No grade level 2 assessments available.</p> // Message when no grade level 2 assessments are available
            )}

            {/* Optional Back Button */}
            <Col>
              <SimpleNavigatioButton
                path="/resultsdashboard"
                label="Back"
                colour="blue"
              />
            </Col>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SencoResultsClassOverview;
