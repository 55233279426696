import React, { useState, useEffect, useCallback } from "react";

import { useNavigate } from "react-router-dom";
import { getSchools, updateStudent } from "../services/APIClient";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";

import PaginationComponent from "./PaginationComponent";

import "./ClassStudents.css";

function ClassStudents(props) {
  let students = props.students;
  let classDetails = props.classDetails;
  let teachers = props.teachers;
  let qrCodeDetails = props.qrCodeDetails;
  let schoolid = props.schoolid;
  let teacherRoles = props.teacherRoles;

  let navigate = useNavigate();

  const [countryId, setCountryId] = useState(null);
  const [studentUpdates, setStudentUpdates] = useState({}); // State to track checkbox values
  const [selectAll, setSelectAll] = useState({
    esl: false,
    sen: false,
    asd: false,
    gdd: false,
    send: false,
  }); // State to track "Select All"

  useEffect(() => {
    let isMounted = true;
    getSchools()
      .then((response) => {
        if (!isMounted) return;
        const matchingSchool = response.data.find(
          (school) => school.id === schoolid
        );
        if (matchingSchool) setCountryId(matchingSchool.countryid);
      })
      .catch((error) => console.error("Error fetching schools:", error));
    return () => {
      isMounted = false;
    };
  }, [schoolid]);

  useEffect(() => {
    if (students) {
      const initialState = {};
      students.forEach((student) => {
        initialState[student.id] = {
          esl: student.esl,
          sen: student.sen,
          asd: student.asd || false,
          gdd: student.gdd || false,
          send: student.send || false,
        };
      });
      setStudentUpdates(initialState);
    }
  }, [students]);

  // Handle individual checkbox changes
  const handleCheckboxChange = (studentId, field, value) => {
    const updatedStudent = {
      ...studentUpdates[studentId],
      [field]: value,
    };
    setStudentUpdates((prev) => ({
      ...prev,
      [studentId]: updatedStudent,
    }));

    // Update student in backend
    updateStudent(studentId, updatedStudent)
      .then((response) => {
        console.log("Student updated successfully", response);
      })
      .catch((error) => {
        console.error("Failed to update student", error);
      });
  };

  // Handle "Select All" checkbox change
  const handleSelectAllChange = (field, value) => {
    setSelectAll((prev) => ({
      ...prev,
      [field]: value,
    }));

    const updatedStudents = { ...studentUpdates };
    students.forEach((student) => {
      updatedStudents[student.id][field] = value;
    });
    setStudentUpdates(updatedStudents);

    // Update all students in backend
    students.forEach((student) => {
      const updatedStudent = updatedStudents[student.id];
      updateStudent(student.id, updatedStudent)
        .then((response) => {
          console.log("Student updated successfully", response);
        })
        .catch((error) => {
          console.error("Failed to update student", error);
        });
    });
  };

  let classStudentsArray = [];
  if (students) {
    classStudentsArray = [...students].sort((a, b) => {
      let fa = a.name.trim().toLowerCase(),
        fb = b.name.trim().toLowerCase();
      if (fa < fb) return -1;
      if (fa > fb) return 1;
      return 0;
    });
  }

  const toStudentDetails = useCallback(
    (student) => {
      navigate("/student/details", {
        state: {
          student: { student },
          classDetails: { classDetails },
          teachers: { teachers },
          qrCodeDetails: { qrCodeDetails },
          students: { students },
          schoolid: { schoolid },
          teacherRoles: { teacherRoles },
        },
      });
    },
    [
      navigate,
      classDetails,
      teachers,
      qrCodeDetails,
      students,
      schoolid,
      teacherRoles,
    ]
  );

  let [paginationItems, setPaginationItems] = useState([]);
  let [itemsPerPage] = useState(8);
  const [currentPage, setCurrentPage] = useState(1);
  const startItemIndex = (currentPage - 1) * itemsPerPage;
  const endItemIndex = startItemIndex + itemsPerPage;
  const currentItems =
    classStudentsArray?.slice(startItemIndex, endItemIndex) || [];

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const irelandId = "07d45b79-24b1-435f-b82a-93861b23a86e";
  const englandId = "74bb2897-dbbe-41e3-b547-caee866545cf";

  return (
    <Row>
      <Col>
        <div>
          <h3 className="class-overview-table-header">
            Class Students ({classStudentsArray.length})
          </h3>
          {classStudentsArray.length <= itemsPerPage ? (
            ""
          ) : (
            <Row>
              <PaginationComponent
                itemsPerPage={itemsPerPage}
                totalItems={classStudentsArray.length}
                handlePageClick={handlePageClick}
              />
            </Row>
          )}
          {classStudentsArray.length === 0 ? (
            <Row className="roles">
              <h5 className="page-description-header">
                There are currently no students in your class.
              </h5>
              <h5 className="page-description-header">
                Select "Add Students" above to start adding your students.
              </h5>
            </Row>
          ) : (
            <Table className="custom-table">
              <thead className="table-header">
                <tr>
                  <th>Name</th>
                  <th>
                    <div className="select-all-container">
                      ESL
                      <input
                        type="checkbox"
                        checked={selectAll.esl}
                        onChange={(e) =>
                          handleSelectAllChange("esl", e.target.checked)
                        }
                      />
                    </div>
                  </th>
                  <th>
                    <div className="select-all-container">
                      Speech &<br />
                      Language
                      <input
                        type="checkbox"
                        checked={selectAll.sen}
                        onChange={(e) =>
                          handleSelectAllChange("sen", e.target.checked)
                        }
                      />
                    </div>
                  </th>
                  {countryId === irelandId && (
                    <>
                      <th>
                        <div className="select-all-container">
                          ASD
                          <input
                            type="checkbox"
                            checked={selectAll.asd}
                            onChange={(e) =>
                              handleSelectAllChange("asd", e.target.checked)
                            }
                          />
                        </div>
                      </th>
                      <th>
                        <div className="select-all-container">
                          GDD
                          <input
                            type="checkbox"
                            checked={selectAll.gdd}
                            onChange={(e) =>
                              handleSelectAllChange("gdd", e.target.checked)
                            }
                          />
                        </div>
                      </th>
                    </>
                  )}
                  {countryId === englandId && (
                    <>
                      <th>
                        <div className="select-all-container">
                          ASD
                          <input
                            type="checkbox"
                            checked={selectAll.asd}
                            onChange={(e) =>
                              handleSelectAllChange("asd", e.target.checked)
                            }
                          />
                        </div>
                      </th>
                      <th>
                        <div className="select-all-container">
                          GDD
                          <input
                            type="checkbox"
                            checked={selectAll.gdd}
                            onChange={(e) =>
                              handleSelectAllChange("gdd", e.target.checked)
                            }
                          />
                        </div>
                      </th>
                      <th>
                        <div className="select-all-container">
                          SEND
                          <input
                            type="checkbox"
                            checked={selectAll.send}
                            onChange={(e) =>
                              handleSelectAllChange("send", e.target.checked)
                            }
                          />
                        </div>
                      </th>
                    </>
                  )}
                  <th>Edit/Change Class</th>
                </tr>
              </thead>

              <tbody>
                {currentItems?.map((student, index) => {
                  const studentCheckboxes = studentUpdates[student.id];
                  return (
                    <tr key={index} className="table-row" id={student.id}>
                      <td className="table-data cursor-on">{student.name}</td>
                      <td>
                        <input
                          type="checkbox"
                          checked={studentCheckboxes?.esl || false}
                          onChange={(e) =>
                            handleCheckboxChange(
                              student.id,
                              "esl",
                              e.target.checked
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          checked={studentCheckboxes?.sen || false}
                          onChange={(e) =>
                            handleCheckboxChange(
                              student.id,
                              "sen",
                              e.target.checked
                            )
                          }
                        />
                      </td>
                      {countryId === irelandId && (
                        <>
                          <td>
                            <input
                              type="checkbox"
                              checked={studentCheckboxes?.asd || false}
                              onChange={(e) =>
                                handleCheckboxChange(
                                  student.id,
                                  "asd",
                                  e.target.checked
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              checked={studentCheckboxes?.gdd || false}
                              onChange={(e) =>
                                handleCheckboxChange(
                                  student.id,
                                  "gdd",
                                  e.target.checked
                                )
                              }
                            />
                          </td>
                        </>
                      )}
                      {countryId === englandId && (
                        <>
                          <td>
                            <input
                              type="checkbox"
                              checked={studentCheckboxes?.asd || false}
                              onChange={(e) =>
                                handleCheckboxChange(
                                  student.id,
                                  "asd",
                                  e.target.checked
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              checked={studentCheckboxes?.gdd || false}
                              onChange={(e) =>
                                handleCheckboxChange(
                                  student.id,
                                  "gdd",
                                  e.target.checked
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              checked={studentCheckboxes?.send || false}
                              onChange={(e) =>
                                handleCheckboxChange(
                                  student.id,
                                  "send",
                                  e.target.checked
                                )
                              }
                            />
                          </td>
                        </>
                      )}
                      <td>
                        <img
                          src="/assets/imgs/edit-icon.svg"
                          className="edit-students-cog"
                          alt="Settings"
                          onClick={() => toStudentDetails(student)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </div>
      </Col>
    </Row>
  );
}

export default ClassStudents;
