import { React, useState, useEffect } from "react";

import { useLocation, useParams } from "react-router-dom";

import HeadingBanner from "../components/HeadingBanner";
import LeftPanel from "../components/LeftPanel";
import IndividualStudentResultsOverview from "../components/IndividualStudentResultsOverview";
import OrientationPrompt from "../components/OrientationPrompt";

import { Container, Row, Col } from "react-bootstrap";

function IndividualStudentResults(props) {
  const location = useLocation();

  console.log(location.state, "LOCATION");

  let name = "Results";

  let classDetails = location.state.classDetails.classDetails;

  let gradeLevel = location.state.gradeLevel.gradeLevel;
  let classAssessments = location.state.classAssessments.classAssessments;
  // let teacherid = location.state.classDetails.classDetails.teacherId;
  let teachers = location.state.teachers.teachers;
  let assessment = location.state.assessment.assessment;
  let student = location.state.student.student;
  console.log(assessment, "ASSESS");

  let taskNameArray = location.state.taskNameArray.taskNameArray;
  let assessmentResultsByStudent =
    location.state.assessmentResultsByStudent.assessmentResultsByStudent;
  let toggleScoreType = location.state.toggleScoreType.toggleScoreType;

  let schoolid = location.state?.schoolid?.schoolid;
  let teacherid = location.state?.teacherid;
  let fromSencoResults = location.state?.fromSencoResults.fromSencoResults;

  console.log("LOCATION:", location.state);
  console.log("schoolid:", assessment);

  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  useEffect(() => {
    window.HubSpotConversations?.widget?.refresh();
  }, []);

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    window.HubSpotConversations?.widget?.refresh();
  }, []);

  console.log(teacherid)

  return (
    <Container fluid className="vh-100">
      <HeadingBanner
        name={name}
        isLoggedIn={true}
        cloud={true}
        teacherid={teacherid}
      />
      <Row className="h-100">
        <LeftPanel />
        <Col sm={{ span: 10, offset: 2 }} className="blue-background">
          <IndividualStudentResultsOverview
            classDetails={classDetails}
            classAssessments={classAssessments}
            // teachers={location.state.teachers}
            // sharedWith={sharedWith}
            // students={students}
            gradeLevel={gradeLevel}
            teachers={teachers}
            assessment={assessment}
            student={student}
            taskNameArray={taskNameArray}
            assessmentResultsByStudent={assessmentResultsByStudent}
            toggleScoreType={toggleScoreType}
            schoolid={schoolid}
            teacherid={teacherid}
            fromSencoResults={fromSencoResults}
          />
        </Col>
      </Row>
      {isPortrait && <OrientationPrompt />}
    </Container>
  );
}

export default IndividualStudentResults;
