export const sandboxcontent = [
  {
    folderName: "Getting Started with the Platform",
    cards: [
      {
        title: "Account Creation",
        iframe:
          '<iframe width="672" height="378" src="https://www.youtube.com/embed/-tHHE3UZsKM?si=iOr0DC_l5HCDJZFv" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
      },
      {
        title: "Navigating the ALPACA Teacher Dashboard",
        iframe:
          '<iframe width="672" height="378" src="https://www.youtube.com/embed/Gd9uNam1YqA?si=SmXSgELkGU6vfMM5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
      },
      {
        title: "Claiming a Converted Classroom",
        iframe:
          '<iframe width="672" height="378" src="https://www.youtube.com/embed/KQE9rRB5BRo?si=T24muP-aOvCwd2q2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
      },
      {
        title: "Importing Class Lists into ALPACA From Aladdin",
        iframe:
          '<iframe width="672" height="378" src="https://www.youtube.com/embed/v5F5Hj-q2b4?si=R_OmFOCDzS0g7Jyz" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
      },
      {
        title: "How to Invite Colleagues",
        iframe:
          '<iframe width="672" height="378" src="https://www.youtube.com/embed/ZzcP4ZAVOBg?si=wqXJeO17YSe98SUB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
      },
      {
        title: "How to Share a Class with a Colleague",
        iframe:
          '<iframe width="672" height="378" src="https://www.youtube.com/embed/WI2EiLKhxo0?si=iAHz2pWisrljga8P" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
      },
    ],
  },
  {
    folderName: "Before Screening Day",
    cards: [
      {
        title: "Hints & Tips For Screening",
        iframe:
          '<iframe width="672" height="378" src="https://www.youtube.com/embed/wQIKM3UAQww?si=EmSSDyKaRShjA7lB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
      },
      {
        title: "Trial Screener Walkthrough",
        iframe:
          '<iframe width="672" height="378" src="https://www.youtube.com/embed/mqD8BKTe58s?si=TaF9cvQ5m_if38wa" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
      },
      {
        title: "Screener Best Practices",
        iframe:
          '<iframe width="672" height="378" src="https://www.youtube.com/embed/6a4sLVTmfFA?si=eg09UK9l0d4gi581" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
      },
    ],
  },
  {
    folderName: "On Screening Day",
    cards: [
      {
        title: "Autumn Jnr Practice",
        iframe:
          '<iframe width="672" height="378" src="https://www.youtube.com/embed/YCC3QQr4DqE?si=DzvBk1dJQfLHpo9o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
      },
      {
        title: "Summer Jnr Practice",
        iframe:
          '<iframe width="672" height="378" src="https://www.youtube.com/embed/CP-U3vo9Znc?si=EWi_vPXD5toFBhZY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
      },
    ],
  },
  {
    folderName: "Results After Screening Day",
    cards: [
      {
        title: "Analysing Results Guide",
        iframe:
          '<iframe width="672" height="378" src="https://www.youtube.com/embed/Ps2u5xhm1Pk?si=ntiGfa6K8ZosoJL3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
      },
      {
        title: "Retake Tasks Guide",
        iframe:
          '<iframe width="672" height="378" src="https://www.youtube.com/embed/SSOmLJBEKTk?si=WTJmdI2_nyEdl9wf" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
      },
    ],
  },

  // Add more folders and their cards as necessary
];

export const additionalResourcesESL = [
  {
    folderName: "Additional Resources",
    cards: [
      {
        title: "Importance Of Early Intervention",
        iframe:
          '<iframe width="672" height="378" src="https://www.youtube.com/embed/J_O0HnA9Njo?si=pOW_-AensxKmtpPw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
      },
      {
        title: "Benefits Of ALPACA",
        iframe:
          '<iframe width="672" height="378" src="https://www.youtube.com/embed/CXOWin_jE0Q?si=2Oetiy_kFoSXwhoQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
      },
      {
        title: "How ALPACA Impacted Teaching",
        iframe:
          '<iframe width="672" height="378" src="https://www.youtube.com/embed/TB1n6IZ5qKM?si=DunpZSlZHb-aU5pt" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
      },
      {
        title: "How To Engage With The Wider Team",
        iframe:
          '<iframe width="672" height="378" src="https://www.youtube.com/embed/-qFj4NJZW18?si=XKZgqyS58UO3gD2x" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
      },
      {
        title: "Why ALPACA",
        iframe:
          '<iframe width="672" height="378" src="https://www.youtube.com/embed/1CVrNF5fCmM?si=rhwwkXzdTagjD3rO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
      },
      {
        title: "Junior Infant Open Evening",
        documentUrl:
          "https://26983596.fs1.hubspotusercontent-eu1.net/hubfs/26983596/SandBox%20Content/Additional%20Resources/Parent%20Teacher%20Meeting%20Presentation.pptx",
      },
      {
        title: "Assessment Best Practices",
        documentUrl:
          "https://26983596.fs1.hubspotusercontent-eu1.net/hubfs/26983596/SandBox%20Content/Additional%20Resources/Assessment%20Best%20Practice%20(2).pdf",
      },
      {
        title: "ALPACA Team Infographic",
        documentUrl:
          "https://26983596.fs1.hubspotusercontent-eu1.net/hubfs/26983596/SandBox%20Content/Additional%20Resources/Alpaca%20Team%20Infographic.pptx",
      },
      {
        title: "ALPACA Screener Observation Sheet",
        documentUrl:
          "https://26983596.fs1.hubspotusercontent-eu1.net/hubfs/26983596/SandBox%20Content/Additional%20Resources/ALPACA%20Screener%20Observation%20Sheet.pdf",
      },

      {
        title: "Areas Screened - Grade Level1",
        documentUrl:
          "https://www.alpaca-assessment.com/hubfs/Knowledge%20base%20files/Guides/Areas%20Screened-GradeLevel1%20Screener.pdf",
      },
      {
        title: "Areas Screened - Grade Level2",
        documentUrl:
          "https://www.alpaca-assessment.com/hubfs/Knowledge%20base%20files/Guides/Areas%20Screened-GradeLevel2%20Screener.pdf",
      },
      {
        title: "Baseline Screener - Next Steps Sep '24",
        documentUrl:
          "https://www.alpaca-assessment.com/hubfs/SandBox%20Content/Additional%20Resources/ALPACA%20Screener%20Journey%20Guide.pptx",
      },
      {
        title: "Early Intervention Tools ALPACA Press Coverage",
        documentUrl:
          "https://26983596.hs-sites-eu1.com/early-intervention-tools-alpaca-press-coverage",
      },
    ],
  },
  {
    folderName: "EAL",
    cards: [
      {
        title: "EAL Introduction",
        iframe:
          '<iframe width="672" height="378" src="https://www.youtube.com/embed/8bJiVLMxeAc?si=efLV_CQF50Pmx6AK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
      },
      {
        title: "Junior Infants Baseline Screener Walk Through",
        iframe:
          '<iframe width="672" height="378" src="https://www.youtube.com/embed/EoMI0IQZotw?si=zrDWzs1thh_pTM4L" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
      },
      {
        title: "Junior Infants Midpoint Screener Walk Through",
        iframe:
          '<iframe width="672" height="378" src="https://www.youtube.com/embed/tQkt0Wq1ZSs?si=FsRn5NtvF4Q79WYN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
      },
      {
        title: "Senior Infants Baseline Screener Walk Through",
        iframe:
          '<iframe width="672" height="378" src="https://www.youtube.com/embed/MhOLBP0oajs?si=O4sX5-Q1hX1pEUsy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
      },
      {
        title: "Senior Infants Midpoint Screener Walk Through",
        iframe:
          '<iframe width="672" height="378" src="https://www.youtube.com/embed/dTYTEW9BAzI?si=XU1HxlCgCWe13po2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
      },
      {
        title: "Junior Infants End of Year Screener Walk Through",
        iframe:
          '<iframe width="672" height="378" src="https://www.youtube.com/embed/CP-U3vo9Znc?si=U1wQNjC3NziGDnKw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
      },

      {
        title: "Junior Infants Word List - Baseline Screener",
        documentUrl:
          "https://26983596.fs1.hubspotusercontent-eu1.net/hubfs/26983596/SandBox%20Content/EAL/Junior%20Infant%20Wordlist%20Baseline%20Screener.pdf",
      },
      {
        title: "Senior Infants Word List - Baseline Screener",
        documentUrl:
          "https://26983596.fs1.hubspotusercontent-eu1.net/hubfs/26983596/SandBox%20Content/EAL/Senior%20Infant%20WordList%20Baseline%20Screener.pdf",
      },
      {
        title: "Junior Infants Word List - Midpoint Screener",
        documentUrl:
          "https://26983596.fs1.hubspotusercontent-eu1.net/hubfs/26983596/SandBox%20Content/Additional%20Resources/GradeLevel1%20Word%20&%20Image%20list%20-%20Midpoint.pdf",
      },
      {
        title: "Senior Infants Word List - Midpoint Screener",
        documentUrl:
          "https://26983596.fs1.hubspotusercontent-eu1.net/hubfs/26983596/SandBox%20Content/Additional%20Resources/GradeLevel2%20Word%20&%20Image%20list%20-%20Midpoint.pdf",
      },
      {
        title: "Junior Infants Word List - End of Year Screener",
        documentUrl:
          "https://26983596.fs1.hubspotusercontent-eu1.net/hubfs/26983596/SandBox%20Content/EAL/Junior%20Infant%20Wordlist%20End%20of%20Year%20Screener.pdf",
      },
    ],
  },
];
