import React, { useState, useEffect, useMemo, Suspense } from "react";
import { useLocation } from "react-router-dom";
import { getClass, getStudents, getQR } from "../services/APIClient";
import { Container, Row, Col } from "react-bootstrap";
import "../components/ClassOverview.css";
import OrientationPrompt from "../components/OrientationPrompt";
import { Fallback, BigSpinner } from "../services/SpinnerComponent";


const HeadingBanner = React.lazy(() => import("../components/HeadingBanner"));
const LeftPanel = React.lazy(() => import("../components/LeftPanel"));
const ResultClassOverview = React.lazy(() =>
  import("../components/ResultClassOverview")
);
const SetResultClassOverview = React.lazy(() =>
  import("../components/SetResultsClassOverview")
);
// const OrientationPrompt = React.lazy(() =>
//   import("../components/OrientationPrompt")
// );





function ResultDetail() {
  const location = useLocation();

  const classDetails = location?.state?.classDetails?.classDetails || {};
  const schoolid = location?.state?.schoolid?.schoolid || null;
  const teacherid = location?.state?.teacherid?.teacherid || null;
  const name = location?.state?.name?.name || "Results";
  const teachers = location?.state?.teachers?.teachers || [];
  const gradeLevel = location?.state?.gradeLevel?.gradeLevel || null;
  const classid = classDetails.id;

  // const [isPortrait, setIsPortrait] = useState(
  //   window.matchMedia("(orientation: portrait)").matches
  // );

  const [state, setState] = useState({
    group: null,
    studentsResponse: null,
    qrCodeDetails: null,
    isPortrait: window.matchMedia("(orientation: portrait)").matches,
    loading: true,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [groupResponse, studentsResponse, qrCodeResponse] =
          await Promise.all([
            getClass(classid),
            getStudents(classid),
            getQR(classid),
          ]);

        setState((prevState) => ({
          ...prevState,
          group: groupResponse,
          studentsResponse,
          qrCodeDetails: qrCodeResponse,
          loading: false,
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
        setState((prevState) => ({ ...prevState, loading: false }));
      }
    };

    fetchData();
  }, [classid]);

  const handleOrientationChange = () => {
    setState((prevState) => ({
      ...prevState,
      isPortrait: window.innerWidth < window.innerHeight,
    }));
  };

  useEffect(() => {
    const debouncedHandleOrientationChange = () => {
      clearTimeout(window.orientationChangeTimer);
      window.orientationChangeTimer = setTimeout(handleOrientationChange, 100);
    };

    window.addEventListener("resize", debouncedHandleOrientationChange);
    return () => {
      window.removeEventListener("resize", debouncedHandleOrientationChange);
    };
  }, []);

  // useEffect(() => {
  //   const handleOrientationChange = () => {
  //     setIsPortrait(window.innerWidth < window.innerHeight);
  //   };

  //   window.addEventListener("resize", handleOrientationChange);

  //   return () => {
  //     window.removeEventListener("resize", handleOrientationChange);
  //   };
  // }, []);

  const students = useMemo(
    () => [
      ...(state.studentsResponse?.students || []),
      ...(state.studentsResponse?.additionalStudents || []),
    ],
    [state.studentsResponse]
  );

  const sharedWith = useMemo(() => state.group?.sharedWith || [], [state.group]);

  if (state.loading) {
    return <BigSpinner />;
  }

  return (
    <Container fluid className="vh-100">
      <Suspense fallback={<div>Loading Heading Banner...</div>}>
        <HeadingBanner name={name} isLoggedIn={true} cloud={true} teacherid={teacherid} />
      </Suspense>
      <Row className="h-100">
        <Suspense fallback={<div>Loading Left Panel...</div>}>
          <LeftPanel />
        </Suspense>
        <Col sm={{ span: 10, offset: 2 }} className="blue-background">
          {classDetails.set_class ? (
            <Suspense fallback={<div>Loading Set Result Class Overview...</div>}>
              <SetResultClassOverview
                classDetails={classDetails}
                teachers={teachers}
                sharedWith={sharedWith}
                students={students}
                additionalStudents={state.studentsResponse?.additionalStudents}
                gradeLevel={gradeLevel}
                name={name}
                qrCodeDetails={state.qrCodeDetails}
                teacherid={teacherid}
                schoolid={schoolid}
              />
            </Suspense>
          ) : (
            <Suspense fallback={<div>Loading Result Class Overview...</div>}>
              <ResultClassOverview
                classDetails={classDetails}
                teachers={teachers}
                sharedWith={sharedWith}
                students={students}
                additionalStudents={state.studentsResponse?.additionalStudents}
                gradeLevel={gradeLevel}
                name={name}
                qrCodeDetails={state.qrCodeDetails}
                teacherid={teacherid}
                schoolid={schoolid}
              />
            </Suspense>
          )}
        </Col>
      </Row>
      {state.isPortrait && (
        <Suspense fallback={<div>Loading Orientation Prompt...</div>}>
          <OrientationPrompt />
        </Suspense>
      )}
    </Container>
  );
}

export default ResultDetail;


// import { React, useState, useEffect } from "react";
// import { useLocation } from "react-router-dom";
// import { getClass, getStudents, getQR } from "../services/APIClient";
// import HeadingBanner from "../components/HeadingBanner";
// import LeftPanel from "../components/LeftPanel";
// import ResultClassOverview from "../components/ResultClassOverview";
// import SetResultClassOverview from "../components/SetResultsClassOverview";
// import OrientationPrompt from "../components/OrientationPrompt";
// import { Container, Row, Col } from "react-bootstrap";
// import "../components/ClassOverview.css";

// function ResultDetail(props) {
//   const location = useLocation();

//   let classDetails = location.state.classDetails.classDetails;
//   let schoolid = location?.state?.schoolid?.schoolid;
//   let teacherid = location?.state?.teacherid?.teacherid;
//   let name = location.state.name.name;
//   let teachers = location.state.teachers.teachers;
//   let gradeLevel = location.state?.gradeLevel?.gradeLevel;

//   let classid = classDetails.id;
//   console.log(teacherid, "Teacher ID");

//   const [group, setGroup] = useState("");
//   const [isPortrait, setIsPortrait] = useState(
//     window.matchMedia("(orientation: portrait)").matches
//   );

//   useEffect(() => {
//     const handleOrientationChange = () => {
//       setIsPortrait(window.innerWidth < window.innerHeight);
//     };

//     window.addEventListener("resize", handleOrientationChange);

//     return () => {
//       window.removeEventListener("resize", handleOrientationChange);
//     };
//   }, []);

//   useEffect(() => {
//     window.HubSpotConversations?.widget?.refresh();
//   }, []);

//   useEffect(() => {
//     getClass(classid).then((response) => {
//       setGroup(response);
//     });
//   }, [classid]);

//   let sharedWith = group.sharedWith;

//   let [studentsResponse, setStudentsResponse] = useState("");

//   useEffect(() => {
//     getStudents(classid).then((response) => {
//       setStudentsResponse(response);
//     });
//   }, [classid]);

//   let students = [
//     ...(studentsResponse.students || []),
//     ...(studentsResponse.additionalStudents || []),
//   ];

//   console.log(studentsResponse.additionalStudents, "RESULTS");

//   let [qrCodeDetails, setQRCodeDetails] = useState("");

//   useEffect(() => {
//     getQR(classid).then((response) => {
//       setQRCodeDetails(response);
//     });
//   }, [classid]);

//   return (
//     <Container fluid className="vh-100">
//       <HeadingBanner
//         name={name}
//         isLoggedIn={true}
//         cloud={true}
//         teacherid={teacherid}
//       />
//       <Row className="h-100">
//         <LeftPanel />
//         <Col sm={{ span: 10, offset: 2 }} className="blue-background">
//           {classDetails.set_class ? (
//             <SetResultClassOverview
//               classDetails={classDetails}
//               teachers={teachers}
//               sharedWith={sharedWith}
//               students={students}
//               additionalStudents={studentsResponse.additionalStudents}
//               gradeLevel={gradeLevel}
//               name={name}
//               qrCodeDetails={qrCodeDetails}
//               teacherid={teacherid}
//               schoolid={schoolid}
//             />
//           ) : (
//             <ResultClassOverview
//               classDetails={classDetails}
//               teachers={teachers}
//               sharedWith={sharedWith}
//               students={students}
//               additionalStudents={studentsResponse.additionalStudents}
//               gradeLevel={gradeLevel}
//               name={name}
//               qrCodeDetails={qrCodeDetails}
//               teacherid={teacherid}
//               schoolid={schoolid}
//             />
//           )}
//         </Col>
//       </Row>
//       {isPortrait && <OrientationPrompt />}
//     </Container>
//   );
// }

// export default ResultDetail;
