import Spinner from 'react-bootstrap/Spinner';

export const Fallback = () => (
  <div className="spinner-container">
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  </div>
);


export const BigSpinner = () => (
    <div className="spinner-container">
      <div className="big-ball-spinner"></div>
    </div>
  );